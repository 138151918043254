// Headings

h1, h2 {
  color: $color-text;
  font-weight: 800;
  line-height: 1.375;
}

h1 {
  font-size: 1rem;
}

h2 {
  font-size: 1.3rem;
  margin: 0 0 8px;
}
