.preloader {
  display: flex;
  width: 100%;
  height: 100vh;
  position: fixed;
  background: white;
  z-index: $z-up-max;
  top: 0;
  justify-content: center;
}

.preloader__media {
  max-width: 300px;
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);

  }

  100% {
    transform: scale(1);
  }
}
