.section--who {
  //background: #f5f5f5;
}

.section--who__paragraph {
  text-indent: 20px;
  line-height: 1.5em;
  text-align: justify;
  margin-bottom: 20px;
}

.section-about-us {
  padding: 0 20px;
}

.section-about-us-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 32px;

  .section-about-us-col {
    width: 100%;

    &:first-child {
      padding-right: 16px;
    }

    &:last-child {
      padding-left: 16px;
    }
  }
}

.section-about-us-paragraph {
  line-height: 1.5em;

  &.align-right {
    direction: rtl;
    text-align: justify;
  }

  &.align-left {
    text-align: justify;
  }
}

.section-about-us-media {
  max-height: 500px;
  max-width: 100%;
  position: relative;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 500px;
    transform: scale(1);
    transition: .3s;

    &:hover {
      transform: scale(1.1);
      box-shadow: 5px 5px 30px -5px $color-grey;
    }
  }
}

@include bp-medium {
  flex-wrap: nowrap;
}
