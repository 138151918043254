// Mixins

/* 320px */
@mixin bp-small {
  @media only screen and (min-width: 480px) {
    @content;
  }
}

/* 768px */
@mixin bp-medium {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

/* 1024px */
@mixin bp-large {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

/* 1600px */
@mixin bp-full {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}
