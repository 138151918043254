// Utilities

/* Hide visually but not from screen readers */
.u-hide {
  left: -9999em !important;
  position: absolute !important;
}

.u-spacer {
  margin-bottom: 1rem;
}

.u-noscroll {
  overflow: hidden;
}

.u-hide\@small {
  display: none;

  @include bp-medium {
    display: block;
  }
}

.small-text {
  text-transform: lowercase;
}

.bold-text {
  font-family: $font-lato-bold;
}

.accent-color {
  color: $color-red-main
}

.indent-text {
  padding-left: 20px;
  width: 10px;
}

.space50px {
  height: 50px;
}

.clickable {
  &:hover {
    cursor: pointer !important;
  }
}
