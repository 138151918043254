.section--contact__wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  @include bp-medium {
    flex-wrap: nowrap;
    height: 350px;
  }
}

.section--contact__info {
  padding: 0 0 32px;
  width: 100%;
  z-index: $z-up-2;
  transform: scale(1);

  &.animate {
    animation: pulse 2s;
  }

  @include bp-medium {
    padding: 16px 0;
  }
}

.section--contact__map {
  width: 100%;
  height: 100%;
  min-height: 300px;
  z-index: $z-up-1;
  border-radius: 4px;
  box-shadow: 2px 2px 10px -1px #c4c4c4;
}

.section--contact__title-paragraph {
  font-size: 1.5em;
  font-family: $font-lato-bold;
  padding: 0 0 10px 0;
}

.section--contact__details-paragraph {
  line-height: 1.2em;
}

