// Layout

.c-container__default {
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;

  @media all and (max-width: 1000px) {
    padding: 0 16px;
  }
}

.c-container__small {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  max-width: 800px;
}
