// Links

.link {
  color: #6bb1ff;

  &:hover {
    color: #6bb1ff;
  }
}

a {
  color: $color-link;
  text-decoration: none;

  &:hover {
    color: $color-text;
  }
}
