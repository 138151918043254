.primary-main__paragraph {
  margin-bottom: 20px;
}

.background__wrapper {
  background: red;
  width: 100%;
  height: 50vh;
  background: url('/assets/images/home/tablice-magnetyczne-cieni-bg.jpg') center no-repeat;
  background-size: cover;
  position: relative;

  @include bp-medium {
    height: 100vh;
  }
}

.main__headers {
  text-align: center;
  color: black;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  text-transform: uppercase;
  font-family: $font-lato-light;
  font-size: 0.8em;
  line-height: 1.7;
  border-radius: 8px;
  padding: 32px;
  white-space: nowrap;

  h3 {
    opacity: .7;
    transition: border-bottom .5s;
    border-bottom: 1px transparent solid;

    &:hover {
      border-bottom: 1px $color-dark-grey solid;
    }
  }

  @include bp-medium {
    top: 27%;
    font-size: 1em;
  }
}

.main__headers__button {
  text-transform: uppercase;
  transition: border-bottom .3s, transform .5s;
  font-family: $font-lato-light;
  width: 300px;
  margin: 5px auto;
  font-size: 1.1em;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: $color-red-main;
  border-bottom: 1px solid transparent;


  &:hover {
    border-bottom: 1px solid $color-red-main;
  }

  @include bp-medium {
    margin: 40px auto;
    font-size: 1.3em;
  }
}

.background {
  width: 100%;
  margin-top: -50px;
}

.info-sections-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 8px;
  text-align: center;

  @include bp-medium {
    flex-wrap: nowrap;
  }
}

.info-section {
  width: 100%;
  padding: 16px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  transition: .3s;
  z-index: 2;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  @include bp-medium {
    width: 33%;
  }

  &:hover {
    svg {
      fill: $color-red-main;
    }
    box-shadow: 0 0 5px -2px #656565;
  }

  svg {
    fill: $color-dark-grey;
    width: 130px;
    transition: .3s;
    height: 100px;
  }
}

.special-label {
  background: $color-red-main;
  padding: 0 40px;
  text-transform: uppercase;
  color: white;
  position: absolute;
  right: -40px;
  top: 24px;
  transform: rotate(45deg);
  box-shadow: 0 0 5px grey;
  z-index: 10;
}

.info-section-text {
  padding-top: 8px;

  h1 {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  p {
    line-height: 1.4;
    color: $color-dark-grey;
    font-family: $font-lato-light;
  }
}