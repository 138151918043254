// Settings global

@font-face {
  font-family: lato-light;
  src: url(/assets/fonts/Lato/Lato-Light.ttf);
  font-display: swap
}

@font-face {
  font-family: lato-bold;
  src: url(/assets/fonts/Lato/Lato-Bold.ttf);
  font-display: swap
}

@font-face {
  font-family: lato-regular;
  src: url(/assets/fonts/Lato/Lato-Regular.ttf);
  font-display: swap
}

.fa {
  padding: 0 10px;
}

.normal-text {
  line-height: 1.5em;
  text-align: justify;
  margin-bottom: 20px;

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.normal-text-no-margin {
  line-height: 1.5em;
  text-align: justify;

  &.no-indent {
    text-indent: 0;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.under-construction {
  width: 80px;
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.full-width {
  width: 100%;
}

.empty-space-32 {
  margin-top: 32px;
}

.empty-space-64 {
  margin-top: 64px;
}

.svg-inline--fa {
  padding: 1px;
}

// Font definitions
$font-georgia: 'Georgia', serif;
$font-times: 'Times', serif;
$font-serif: $font-times;
$font-lato-light: 'lato-light';
$font-lato-bold: 'lato-bold';
$font-lato-regular: 'lato-regular';

//z-indexes
$z-under: -1;
$z-normal: 0;
$z-up-1: 1;
$z-up-2: 2;
$z-up-2: 3;
$z-up-max: 99;
