.realizations {
  margin-top: -10px;
}

.realization_block_wrapper {

}

.realization_block {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transform: translateY(-50%)
  }
}

.realization_overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: .5s;
  cursor: pointer;

  h3 {
    margin: 0 auto;
    opacity: 0;
    transition: .5s;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.8);

    h3 {
      opacity: 1;
    }
  }
}

.visualisation_trigger {
  position: absolute;
  top: 0;
  right: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px -2px #444;
  transition: .5s;
  color: #444;
  text-align: center;
  opacity: .5;
  z-index: 5;
}

.visualisation_trigger .visualisation_icon {
  font-size: 3em;
  padding: 8px 8px 0
}

.visualisation_trigger p {
  padding: 0 8px 8px;
  cursor: default
}

.visualisation_trigger:hover {
  background: #e33712;
  color: #fff
}

.realization_wrapper {
  position: relative;
  margin-bottom: 32px;
}

.realization_gallery {
  position: relative;
  padding-bottom: 20px
}

.realization_gallery > div {
  background: #fff
}

.realization_gallery img {
  max-height: 500px;
  margin: 0 auto;
}

.realizations_slider {
  height: 600px;
  margin-bottom: 32px;
}

.realization_slide {
  position: relative;
}

.realization_gallery .realization_real {
  z-index: 2;
  opacity: 1;
  transition: opacity .5s;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0
}

.realization_gallery .realization_real.hover {
  opacity: 0
}

.slick-arrow {
  position: absolute;
  top: 300px;
  z-index: 5;
  font-size: 1.2em !important;
  color: #444;
  padding: 16px;
  border-radius: 5px
}

.realizations_nav_slider {
  .realization_nav_slide {
    opacity: 0.3;
    transition: .3s;

    &.slick-current {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }

    .realization_nav_gallery {
      width: 200px;
      height: 200px;
      margin: 0 auto;

      .realization_nav_real {
        position: relative;
        overflow: hidden;
        width: 250px;
        height: 200px;
        background-size: cover;
        background-position: center;
      }
    }
  }
}

.slick-arrow:hover {
  cursor: pointer
}

.slick-arrow.left {
  left: 20px
}

.slick-arrow.right {
  right: 20px
}

.realization_details {
  text-align: left;
  padding: 0 20px 20px 20px;
  z-index: 5;
}

.realization_details .realization_title {
  font-size: 1.2em;
  position: absolute;
  z-index: 9;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.indent-text {
  padding-left: 20px;
  width: 10px
}

.space50px {
  height: 50px
}

.clickable:hover {
  cursor: pointer !important
}
