.divider {
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin: 0px auto 20px;
  position: relative;

  a {
    font-size: 0.8em;
    &:hover {
      text-decoration: underline;
    }
  }
}

.divider__links {
  padding: 5px 20px;

  p {
    a {
      &:last-child {
        @extend .bold-text
      }
    }
  }
}

.divider__title__wrapper {
  margin: 0;
  width: 100%;
  text-align: center;
  font-family: $font-lato-bold;
  line-height: 20px;
  position: relative;
  background: $color-lighter-grey;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.divier__title {
  margin: 0 auto;
  text-align: left;
  padding: 10px 20px;
  white-space: nowrap;
  width: 100%;
  max-width: 1000px;
}

.divider__next-product {
  background: $color-red-main;
  color: white;
  position: relative;
  display: flex;
  height: 45px;
  width: 47px;
  text-align: center;
  align-items: center;

  svg {
    margin: 0 auto !important;
    text-decoration: none;
  }

  @include bp-small {
    margin-right: 20px;
  }
}

.divider__title__paragraph {
  font-size: 1em;
  margin: 0;
}
