#cookieinfo * {
  margin: 0;
  padding: 0;
}

#cookieinfo {
  font-size: 12px;
  font-family: Arial, Verdana, sans-serif;
  left: 0;
  right: 0;
  padding: 20px;
  box-shadow: 0 0 5px -3px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  position: fixed;
  bottom: 0;
  background: white;
}

#cookieinfo h6 {
  font-size: 16px;
  font-weight: bold;
  color: #cdd7da;
  margin-bottom: 7px;
}

#cookieinfo p {
  font-size: 12px;
  color: #afb4bc;
  line-height: 1.5em;
}

#cookieinfo a {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  line-height: 22px;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #ff6000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
}

#cookieinfo a:hover {
  background-color: #ff7925;
}
