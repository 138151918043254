.detailed-offer {
  &:first-child {
    background: red;
  }
}

.detailed__row__header {
  display: flex;
  width: 100%;
  padding: 20px 10px 15px 10px;
  align-items: center;

  div {
    &:nth-child(1) {
      flex: 3
    }
    &:nth-child(2) {
      flex: 3
    }
    &:nth-child(3) {
      flex: 1
    }
    &:nth-child(4) {
      flex: 1
    }
  }
}

.detailed__row {
  display: flex;
  width: 100%;
  padding: 10px 10px 5px 10px;
  align-items: center;
  border-top: $color-grey 1px solid;

  &:first-child {
    background: red;
  }

  & .lightgallery {
    display: flex;
  }

  div {
    &:nth-child(1) {
      flex: 3
    }
    &:nth-child(2) {
      flex: 3
    }
    &:nth-child(3) {
      flex: 1
    }
    &:nth-child(4) {
      flex: 1
    }
  }

  * {
    &:hover {
      z-index: $z-up-max;
    }

    img {
      max-width: 80px;
      max-height: 80px;
      margin: 0 10px;
      transform: scale(1);
      transition: .5s;

      &:hover {
        transform: scale(1.2);
        box-shadow: 10px 10px 20px $color-grey;
      }
    }
  }
}

.detailed__envelope__wrapper,
.detailed__pdf__wrapper {
  text-align: center;

  p {
    line-height: 1;
    text-transform: uppercase;
    font-size: 0.8em;
  }
}

