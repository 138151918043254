footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.primary-footer__wrapper {
  background: $color-dark-grey;
  width: 100%;
  height: 80px;
  box-shadow: 1px 1px 10px $color-dark-grey;
  display: flex;
  align-items: center;
}

.primary-footer {
  display: flex;
  flex-wrap: wrap;

  p {
    color: white;
    opacity: 0.7;
    font-size: 0.8em;
    align-self: center;
    margin: 5px auto;

    @include bp-medium {
      margin: 0;
    }
  }

}

.footer-nav {
  display: flex;
  list-style: none;
  font-size: 0.6em;
  color: white;
  align-self: center;
  flex-wrap: wrap;
  margin: 0 auto;

  li {
    padding: 0 10px;
    margin: 0;
    border-right: 1px dashed white;

    &:last-child {
      border: none;
    }
  }

  a {
    color: $color-grey;
  }

  @include bp-medium {
    margin: 0 0 0 auto;
  }
}
