.slider__wrapper {
  position: relative;
  background: $color-white;
  height: 100vh;
  margin-top: 20px;
}

.slider {
  text-align: center;
  width: 100%;
  height: 100vh;
}

.slider__slide {
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: center;

  &:focus {
    outline: none;
  }

  img {
    margin: 0 auto;
    height: 100%
  }
}

.slider {
  .slick-track {
    height: 100%;
  }

  .slick-list {
    overflow: hidden;
    height: 100%;
  }

  .slick-left,
  .slick-right {
    position: absolute;
    top: 50%;
    z-index: 10;
    transition: transform 0.5s;
    transform: scale(0.9) translate(-50%, -50%);
    display: none;

    &:hover {
      transform: scale(1) translate(-50%, -50%);
    }

    @include bp-medium {
      //display: inline;
    }
  }

  .slick-left {
    left: 20px;
  }

  .slick-right {
    right: 20px;
  }

  .slick-dots {
    margin: 0;

    li {
      display: inline;
      padding: 5px;

      button {
        border: none;
        height: 10px;
        width: 20px;
        font-size: 0.1em;
        color: transparent;
        margin: 5px 0;
        background: #e6e6e6;

        &:hover {
          cursor: pointer;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }

      &.slick-active {
        button {
          background: $color-red-main;
          height: 10px;
          width: 20px;
        }
      }
    }
  }
}
