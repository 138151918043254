.header__wrapper {
  border: none;
  z-index: 99;
  top: 0;
  width: 100%;
  background: white;
  border-bottom: $color-red-main 2px solid;
}

.header {
  margin: 0 auto;

  @include bp-medium {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
}

.header__media-wrapper {
  text-align: center;
  height: 50px;
  width: 100%;
  padding: 5px;

  @include bp-small {
    width: 30%;
    height: 70px;
    text-align: left;
  }
}

.header__media {
  height: 100%;

  &.christmas {
    margin-top: -5px;
  }
}

.header__nav__wrapper {
  width: 100%;
  margin-bottom: 2px;

  @include bp-medium {
    width: 70%;
  }
}

.header__nav__list {
  display: none;
  list-style: none;
  overflow: visible;
  border-top: 1px solid $color-light-grey;
  box-sizing: border-box;
  transition: max-height .5s;
  z-index: $z-up-1;

  @include bp-medium {
    max-height: none;
    display: flex;
    width: 100%;
    margin: 0 0 0 auto;
  }
}

.header__nav__list-responsive {
  list-style: none;
  overflow: hidden;
  border-top: 1px solid $color-light-grey;
  box-sizing: border-box;
  transition: max-height .5s;
  z-index: $z-up-1;
  display: none;

  @include bp-medium {
    display: none;
  }
}

.header__nav__element {
  width: 100%;
  margin: 0;
  padding: 5px;
  text-align: left;
  transition: .5s;
  background: $color-white;
  position: relative;

  .nav-extended {
    z-index: 99;
    opacity: 0;
    max-height: 0;
    transition: .3s;
    overflow: hidden;
    position: absolute;
    box-shadow: 0 1px 5px -2px #656565;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    a {
      padding: 4px 16px;
      transition: .3s;

      &:hover {
        color: $color-red-main;
      }
    }
  }

  &:hover {
    background-color: $color-light-grey;
    cursor: pointer;
    color: $color-red-main;

    & > a {
      color: $color-red-main;
    }

    .nav-extended {
      opacity: 1;
      max-height: 180px;
    }
  }

  a {
    display: block;
    height: 100%;
    transition: .3s;
  }

  @include bp-medium {
    border: none;
    text-align: center;

    .nav-extended {
      position: absolute;

      a {
        background: white;
        border-bottom: 1px solid $color-lighter-grey;
        text-shadow: none;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.header__nav__list-contact {
  display: flex;
  margin: 0 0 0 auto;
  width: 100%;
  background: $color-white;
  align-items: flex-end;
  justify-items: flex-end;
}

.header__nav__element-contact:first-child {
  margin: 0 16px 0 auto;
}

.header__nav__element-contact {
  list-style: none;
  text-align: center;
  margin: 0;
}

.nav__trigger-button__wrapper {
  width: 100%;
  min-height: 20px;
  padding: 12px;

  @include bp-small {
    width: auto;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  @include bp-medium {
    display: none;
  }
}

.nav__trigger-bar {
  background: $color-red-main;
  width: 25px;
  height: 2px;
  margin: 3px auto;
  transition: transform 0.5s;

  @include bp-small {
    margin-right: 8px;
  }
}

.nav__trigger-button__wrapper.is-triggered {
  .nav__trigger-bar {
    &:nth-child(1) {
      transform: rotate(45deg) translateY(3px);
    }

    &:nth-child(2) {
      transform: rotate(-45deg) translateY(-4px);
    }

    &:nth-child(3) {
      display: none;
    }
  }
}

.shop-link {
  width: 100%;
  max-width: 500px;
  margin: 16px auto;
  border-radius: 4px;
  text-align: center;
  background: linear-gradient(45deg, $color-red-main 0%, #f33717 100%);
  cursor: pointer;
  transform: scale(1);
  transition: .3s;
  color: white;

  svg {
    margin-left: 4px;
  }

  &:hover {
    transform: scale(1.1);
  }
}
