.sm__wrapper {
  display: flex;
  flex-wrap: wrap;

  @include bp-small {
    flex-wrap: nowrap;
  }
}

.sm__description,
.sm__gallery {
  padding: 10px;
  width: 100%;
  @include bp-small {
  }
}

.sm__description {
  @include bp-small {
    flex: 5;
  }
}

.sm__gallery {
  .lightgallery {
    display: flex;
    flex-wrap: wrap;

    a {
      width: 100%;
      transition: .5s;
      padding: 10px;
      transform: scale(0.9)
    }

    a:hover {
      box-shadow: 15px 15px 30px $color-grey;
      transform: scale(1);
      z-index: $z-up-2;
    }

    img {
      width: 100%;
    }
  }

  @include bp-small {
    flex: 3;
  }
}

.sm__paragraph {
  text-align: justify;
  margin-bottom: 15px;
  line-height: 1.3em;
  font-size: 0.9em;
}

.sm__details {
  width: 100%;
}

.sm__details__header__wrapper {
  background: $color-light-grey;
  text-align: center;
  margin-top: 50px;
}

.sm__details__header {
  text-transform: uppercase;
  line-height: 1em;
  padding: 20px;
}

.sm__details__paragraph__wrapper {
  background: $color-lighter-grey;
  padding: 15px;
}

.sm__details__title {
  font-family: $font-lato-bold;
}

.sm__details__paragraph {
  padding-left: 15px;
  line-height: 1.4em;
  font-size: 0.9em;
}
