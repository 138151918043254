.section--recommend__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.section--recommend__slide {
  position: relative;
  width: 100%;
  padding: 8px;
  text-align: center;
  margin: 20px auto 0;

  &:hover {
    .section--recommend__title {
      color: $color-red-main;
    }
  }

  @include bp-small {
    width: 33%;
  }

  &.max-height {
    .section--recommend__media {
      max-height: 200px;
    }
  }

  &.small {
    width: 50%;

    @include bp-small {
      width: 25%;
    }

    .section--recommend__media {
      max-height: 400px;
    }
  }

  &.big {
    @include bp-small {
      width: 50%;
    }

    .section--recommend__media {
      max-height: 400px;
    }
  }
}

.section--recommend__media__wrapper {
  line-height: 0;
}

.section--recommend__media {
  max-width: 100%;
  //max-height: 150px;
}

.section--recommend__info {
  position: relative;
  transform: scale(1);
  transition: 0.5s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 5px 5px 15px $color-grey;
  }
}

.section--recommend__slide__link {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.section--recommend__title {
  background: $color-white;
  color: $color-black;
  white-space: nowrap;
  text-align: center;
  line-height: 0.5;
  margin: 0 auto;
  padding: 15px 0;
  width: 100%;
  z-index: 10;
  border-top: 1px $color-grey solid;
  transition: .5s;
  font-size: 0.9em;
}
