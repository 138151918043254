// Page

html {
  background: $color-white;
  color: $color-text;
  font-family: $font-lato-regular;
  font-size: 0.775rem;
  line-height: 2;

  @include bp-medium {
    font-size: 0.9em;
  }

  @include bp-large {
    font-size: 1.1em;
  }

  @include bp-full {
    font-size: 1.15em;
  }
}


html {
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
}

main {
  padding-bottom: 70px;
}
