.product__section {
  margin-top: 170px;

  @include bp-small {
    margin-top: 120px;
  }

  @include bp-medium {
    margin-top: 130px;
  }
}

.product__header {
  font-size: 1.2em;
  font-family: $font-lato-bold;
  margin: 150px 0 50px 0;

  @include bp-small {
    margin: 100px 0 50px 0;
  }
}

.product__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.product__gallery {
  flex: 100%;
  margin: 20px;
  min-height: 400px;

  @include bp-medium {
    flex: 3
  }
}

.product__tech__wrapper {
  display: none;
  flex: 1;
  padding: 20px;

  @include bp-medium {
    flex: 5;
  }
}

.product__tech__wrapper.current {
  display: block;
}

.product__tech__media {
  width: 100%;
}

.product__info__wrapper {
  width: 100%;
  padding: 20px;
  min-height: 500px;

  @include bp-medium {
    width: 40%;
  }
}

.product__info__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;
}

.product__info__tab {
  background: none;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0;
  text-align: center;
  width: 100%;
  background: $color-lighter-grey;
  white-space: nowrap;
  z-index: $z-normal;
  border-bottom: 1px $color-grey solid;

  &:hover {
    color: $color-red-main;
  }
}

.product__info__tab.current {
  background: white;
  border-top: 1px $color-grey solid;
  border-left: 1px $color-grey solid;
  border-right: 1px $color-grey solid;
  border-bottom: transparent;
  color: $color-red-main;
  z-index: $z-up-2;
}

.product__info__tab-content {
  display: none;
  padding: 15px;
  min-height: 90%;
  border-bottom: 1px $color-grey solid;
  border-left: 1px $color-grey solid;
  border-right: 1px $color-grey solid;

  & .button {
    text-align: center;
    margin-top: 30px;
    color: black;
    transition: .5s;

    i {
      box-shadow: 2px 2px 4px -1px #c4c4c4;
      padding: 16px;
      border-radius: 4px;
    }

    &:hover {
      color: $color-red-main
    }
  }
}

.product__info__tab-content.current {
  display: inherit;
  z-index: $z-up-1;
}

.tab-content__description {
  .tab-content__paragraph {
    margin: 5px 0;
  }
}

.tab-content__paragraph {
  line-height: 1.3em;
  font-size: 0.8em;
}

.product.section--recommend {
  .divider__title__wrapper {
    background: none;
  }

  .divier__title {
    border-bottom: 1px solid $color-grey;
  }

  .section--recommend__slide {
    margin-top: 0;
  }

  .section--recommend__media__wrapper {
    text-align: center;
  }

  .section--recommend__media {
    max-height: 200px;
    width: auto;
  }
}

.product__button__wrapper {
  text-align: right;
  position: relative;
}

.product-paragraph {
  line-height: 1.8;

  &.centered {
    text-align: center;
  }

  &.align-right {
    //direction: rtl;
    text-align: justify;
  }

  &.align-left {
    text-align: justify;
  }
}

.big-link {
  color: #2bb7ff;
  font-size: 1.5em;
  margin: 32px 0;
  text-decoration: underline;
}

.product__button {
  background: $color-red-main;
  color: white;
  width: 270px;
  text-align: center;
  position: absolute;
  right: 20px;
  border-radius: 2px;

  svg {
    margin-left: 8px;
  }
}

.product-gallery {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 90px;

  .dimension {
    display: flex;
    align-items: center;
    margin: 16px 0 8px 0;
  }

  .square {
    width: 12px;
    height: 12px;
    background: $color-red-main;
    margin-right: 8px;
  }

  .product-gallery-item {
    height: 180px;
    width: 50%;
    padding: 8px;
    overflow: hidden;
    cursor: pointer;
    transform: scale(1);
    transition: .3s;
    max-width: 150px;

    &.pdf {
      background: white;
      margin: 8px;
      box-shadow: 1px 1px 4px -1px #c4c4c4;
      height: 180px - 16px;
      border-radius: 2px;
      max-width: 150px - 16px;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-red-main;
      }
    }

    a {
      width: 100%;
      height: 100%;
    }

    &:hover {
      transform: scale(1.05);
    }

    @include bp-medium {
      width: 25%;
      max-width: none;
      padding: 8px;
    }

    @include bp-large {
      width: 14%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 2px 2px 4px -1px #c4c4c4;
    border-radius: 2px;

    @include bp-medium {
      box-shadow: 2px 2px 4px -1px #c4c4c4;
    }
  }
}

.product-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  align-items: flex-start;
  width: 100%;

  .product-col {
    width: 100%;
    margin-bottom: 16px;

    &.padding-right {
      padding-right: 0;
    }

    &.padding-left {
      padding-left: 0;
    }
  }

  .product-media {
    max-width: 100%;
    position: relative;
    text-align: center;
    margin: 0 auto;

    &.width-limited {
      max-width: 500px;
      text-align: center;
      margin: 0 auto;

      @include bp-medium {
        margin-left: auto;
      }
    }

    img {
      max-width: 100%;
      max-height: 400px;
    }
  }

  .sample-product {
    width: 100%;
    text-align: center;

    img {
      margin: 0 auto;
    }
  }

  @include bp-medium {
    flex-wrap: nowrap;

    .product-col {
      &.padding-right {
        padding-right: 16px;
      }

      &.padding-left {
        padding-left: 16px;
      }
    }
  }
}