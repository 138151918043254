.section--offer {
  display: flex;
  flex-wrap: wrap;

  @include bp-medium {
    flex-wrap: nowrap;
  }
}

.section--offer__sidebar {
  display: block;
  text-transform: capitalize;

  width: 100%;

  @include bp-medium {
    width: 30%;

  }
}

.section--offer__content {
  width: 100%;

  @include bp-medium {
    width: 80%;
  }
}

.section--offer__ul {
  list-style: none;
  margin: 0;
}

.section--offer__li {
  border-bottom: $color-red-main 1px solid;
}

.section--offer__link {
  display: block;
}

.section--offer__content__title {
  text-transform: capitalize;
  margin: 0 40px
}

.section--offer__carousel {
  position: relative;
  text-align: center;
}
