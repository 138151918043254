.contact-form__wrapper {
  margin-bottom: 60px;
  display: none;
}

.contact-form {
  padding: 40px;
}

.contact-form__button {
  background: $color-red-main;
  color: white;
  width: 250px;
  text-align: center;
  position: absolute;
  right: 20px;
  border-radius: 2px;

  svg {
    margin-left: 8px;
  }
}

.contact-form__input {
  width: 100%;
  border-radius: 0;
  border: white;
  transition: .5s;
  box-shadow: 1px 1px 7px $color-grey;
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px transparent solid;

  &:focus {
    outline: none;
    border-bottom: 1px $color-red-main solid;
  }
}

.contact-form__textarea {
  width: 100%;
  border-radius: 0;
  border: white;
  transition: .5s;
  box-shadow: 1px 1px 7px $color-grey;
  box-sizing: border-box;
  padding: 10px;

  &:focus {
    outline: none;
    border-bottom: 1px $color-red-main solid;
  }
}

.contact-form__warning {
  color: $color-red-main;
  display: none;
  padding: 20px;
}

.loader {
  text-align: right;
  display: none;
}

.rodo-message {
  margin: 16px 0;
  color: grey;
  line-height: 1.2;
}